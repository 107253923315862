.realestate-buttons {
  display: flex;
  justify-content: center;
  padding: 20px;
  margin-top: 10vh;
  font-family: "manrope";
}

/* Default button styles */
.real-button,
.real-button {
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

/* Real Estate button active styles */
/* .real1-button.active {
    background-color: #007BFF;
    color: white;
  }
   */
/* Default state for Real Estate button */
.real-button {
  background-color: white;
  color: blue !important;
}

/* Others button default styles */
/* .real2-button {
    background-color: white;
    color: #007BFF; 
    border: 1px solid #007BFF;
  } */

/* Others button active styles */
.real-button.active {
  background-color: blueviolet; /* Tomato color or any color of your choice */
  color: white !important;
  border: none;
}

/* Hover effects */
.real-button:hover,
.real-button:hover {
  opacity: 0.9;
}
