/* src/components/VideoSection.css */

.video_product {
  padding: 2rem 0;
  background-color: #efefef;
  text-align: left;
  box-sizing: border-box;
  font-family: "manrope";
}

.video-section {
  /* max-width: 1400px; */
  margin: 0 50px;
  padding: 2rem;
  background-color: #d9d9d9;
  border-radius: 20px;
  box-sizing: border-box;
}

.video-section-header {
  font-size: 2rem;
  margin-bottom: 2rem;
  text-align: center;
  font-family: "Space Grotesk";
}

.video-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.video-buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 2rem;
}

.video-button {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;

  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.video-button.active,
.video-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.vid_wrap {
  display: flex;
  flex-direction: row;
  /* align-items: flex-start; */
  gap: 2rem;
}

.video-wrapper {
  flex: 1;
  max-width: 60%;
}

.video {
  width: 100%;
  border-radius: 20px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.video:hover {
  box-shadow: 0 0 20px rgba(255, 0, 255, 0.7);
  transform: scale(1.02);
}

.text-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.video-title {
  font-size: 2rem;
  margin-bottom: 1rem;
  font-family: "Space Grotesk";
}

.video-description {
  font-size: 1rem;
  margin-bottom: 1.5rem;
}

.video-features {
  list-style-type: none;
  padding: 0;
  margin-bottom: 1.5rem;
}

.video-features li {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.info-button {
  align-self: center;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;

  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.info-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

/* Media Queries for Responsive Design */
@media (max-width: 1024px) {
  .vid_wrap {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .video-wrapper {
    max-width: 100%;
    /* margin-bottom: 2rem; */
  }
}

@media (max-width: 768px) {
  .video_product {
    margin: 10px;
  }
  .video-section {
    margin: 0 20px;

    padding: 1.5rem;
  }

  .video-section-header {
    font-size: 1.75rem;
  }

  .video-title {
    font-size: 1.75rem;
  }

  .video-description {
    font-size: 0.9rem;
  }

  .video-features li {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .video-section {
    margin: 0 10px;

    padding: 1rem;
  }

  .video-section-header {
    font-size: 1.5rem;
  }

  .video-buttons {
    /* flex-direction: column; */
    /* width: 100%; */
  }

  .video-button {
    /* width: 100%; */
  }

  .video-title {
    font-size: 1.5rem;
  }

  .video-features li {
    font-size: 0.9rem;
  }

  .info-button {
    align-self: center;
    width: 100%;
  }
}
