/* src/components/ContactUs.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.contact-form {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  width: 90%;
  max-width: 500px;
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.18);
  transition: all 0.3s ease-in-out;
  font-family: "manrope";
}

.contact-title {
  color: #fff;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  text-align: center;
  font-weight: 700;
}

.form-group {
  margin-bottom: 1rem;
}

.contact-form label {
  display: block;
  color: #fff;
  margin-bottom: 0.5rem;
  font-size: 1rem;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 0.75rem;
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  font-size: 1rem;
}

.contact-form textarea {
  min-height: 100px;
  resize: vertical;
}

.contact-form button {
  width: 100%;
  padding: 0.75rem;
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1rem;
  font-weight: bold;
}

.contact-form button:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.contact-form button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.social-icons {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
}

.social-icons a {
  margin: 0 0.5rem;
  color: #fff;
  font-size: 1.5rem;
  transition: color 0.3s ease, transform 0.3s ease;
}

.social-icons a:hover {
  color: rgba(255, 255, 255, 0.7);
  transform: scale(1.1);
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .contact-form {
    padding: 1.5rem;
  }

  .contact-title {
    font-size: 1.3rem;
  }

  .contact-form label,
  .contact-form input,
  .contact-form textarea,
  .contact-form button {
    font-size: 0.9rem;
  }

  .social-icons a {
    font-size: 1.3rem;
  }
}

@media (max-width: 480px) {
  .contact-form {
    padding: 1rem;
  }

  .contact-title {
    font-size: 1.2rem;
  }

  .contact-form label,
  .contact-form input,
  .contact-form textarea,
  .contact-form button {
    font-size: 0.85rem;
  }

  .social-icons a {
    font-size: 1.2rem;
  }
}
