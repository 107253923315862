/* src/components/Product.css */

.produc-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  /* margin: 20px 0; */
  padding: 20px;

  background-color: #efefef;
  /* border: 2px solid black; */
  max-width: 1300px;
  margin: 0 auto;
  font-family: "manrope";
  text-align: left;
}
.produc-container:first-child {
  margin-top: 10vh;
}

.produc-container.reverse {
  flex-direction: row-reverse;
}

.produc-content {
  flex: 1;
  padding: 20px;
  text-align: left;
}

.produc-content .titlee_text {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 15px;
  color: black;
}

.produc-content .desc {
  font-size: 1rem;
  margin-bottom: 20px;
  line-height: 1.3;
  /* color: rgb(88, 79, 79); */

  color: #121212;

  font-size: 20px;
  /* font-style: normal; */
  font-weight: 300;
  /* line-height: normal; */
}

.produc-content button {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s;
}

.produc-content button:hover {
  background-color: #007bff;

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

.produc-image {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.produc-image .pro_img {
  width: 38vw;
  height: 45vh;
  border-radius: 10px;
}

.contact-form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.contact-form-container {
  background: #fff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
}

/* /////////////////////responsive/////////////// */

/* Media Queries for Mobile and Small Devices */
@media (max-width: 768px) {
  .produc-container {
    flex-direction: column-reverse;
    padding: 10px;
    /* border: 1px solid black; */
    margin: 50px 10px;
  }

  .produc-container.reverse {
    flex-direction: column-reverse;
  }

  .produc-content {
    padding: 10px;
    display: flex;
    flex-direction: column;
    text-align: left;

    align-items: center;
    justify-content: center;
  }

  .produc-content .sub_text {
    font-size: 2rem;
  }

  .produc-content .titlee_text {
    font-size: 1.5rem;
  }

  .produc-content .desc {
    font-size: 1rem;
  }

  .produc-content button {
    padding: 8px 16px;
    font-size: 14px;
  }

  .produc-image .pro_img {
    width: 90vw;
    height: auto;
  }
}

@media (max-width: 480px) {
  .produc-container {
    flex-direction: column-reverse;
    padding: 10px;
    /* border: 1px solid black; */
    margin: 40px 10px;
  }
  .produc-content .sub_text {
    font-size: 1.5rem;
  }

  .produc-content .titlee_text {
    font-size: 1.2rem;
  }

  .produc-content .desc {
    font-size: 1rem;
  }

  .produc-content button {
    padding: 6px 12px;
    font-size: 14px;
  }
}
