/* TestimonialSlider.css */
.testimonial-container {
  width: 100%;
  max-width: 1400px;
  margin: 5vh auto;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "manrope";
}

@keyframes containerAnimation {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@keyframes glowAnimation {
  0% {
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.3);
  }
  100% {
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.5);
  }
}

.testimonial-container::before,
.testimonial-container::after {
  content: "";
  position: absolute;
  top: -15px;
  left: -15px;
  right: -15px;
  bottom: -15px;
  border-radius: 15px;
  z-index: -1;
}

.testimonial-container::before {
  background: rgba(255, 255, 255, 0.1);
}

.testimonial-container::after {
  background: radial-gradient(
    circle at center,
    rgba(255, 255, 255, 0.05),
    transparent
  );
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
}

.testimonial-title {
  font-size: 3rem;
  margin-bottom: 20px;
  /* color: #fffcfc; */
  font-weight: 700;
  font-family: "Space Grotesk";
}

.testimonial-content {
  background-color: white;
  color: black;
  border-radius: 10px;
  padding: 20px;
  width: 80%;
  min-height: 200px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.testimonial-content::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(90deg, #3498db, #2ecc71);
  transform: translateX(-100%);
}

.testimonial-content.animate-line::before {
  animation: slideLine 3s linear;
}

@keyframes slideLine {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

.testimonial-text {
  font-size: 1.25rem;
  /* margin-bottom: 15px; */
  line-height: 1.6;
}

.testimonial-company {
  font-size: 1.1rem;
  font-weight: bold;
  color: #110202;
}

.testimonial-position {
  font-size: 1rem;
  color: black;
  margin-top: 5px;
}

.dot-indicators {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.dot {
  width: 8px;
  height: 8px;
  margin: 0 5px;
  background-color: #ccc;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dot.active {
  background-color: #3498db;
}

.dot:hover {
  background-color: #2ecc71;
}

.star-rating {
  font-size: 24px;
  color: #ccc;
  margin: 10px 0;
}

.star {
  display: inline-block;
  margin: 0 2px;
  text-align: center;
}

.star.filled {
  color: gold;
}

/* Animations for slide transitions */
.slide-in {
  animation: slideIn 0.5s forwards;
}

.slide-out {
  animation: slideOut 0.5s forwards;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-50px);
  }
}

/* Responsive design */
@media (max-width: 1024px) {
  .testimonial-container {
    width: 90%;
  }

  .testimonial-title {
    font-size: 1.75rem;
  }

  .testimonial-text {
    font-size: 1.15rem;
  }

  .testimonial-company {
    font-size: 1rem;
  }

  .testimonial-position {
    font-size: 0.9rem;
  }
}

@media (max-width: 768px) {
  .testimonial-container {
    width: 95%;
    padding: 15px;
  }

  .testimonial-title {
    font-size: 1.5rem;
  }

  .testimonial-text {
    font-size: 1rem;
  }

  .testimonial-company {
    font-size: 0.95rem;
  }

  .testimonial-position {
    font-size: 0.85rem;
  }
}

@media (max-width: 480px) {
  .testimonial-container {
    width: 100%;
    padding: 10px;
    margin: 2vh auto;
  }

  .testimonial-content {
    min-height: 250px;
  }

  .testimonial-title {
    font-size: 1.25rem;
  }

  .testimonial-text {
    font-size: 0.9rem;
  }

  .testimonial-company {
    font-size: 0.85rem;
  }

  .testimonial-position {
    font-size: 0.8rem;
  }

  .dot {
    width: 6px;
    height: 6px;
  }

  .star-rating {
    font-size: 20px;
  }
}
