:root {
  --logo-height: 8vh;
  --logo-height-mobile: 5vh;
}

.navv-container {
  display: block;
  width: 100%;
  font-family: "manrope";
}

.header-nav {
  position: fixed;
  top: 0;
  left: 0;
  height: 10vh;
  width: 100%;
  background-color: rgba(15, 17, 22, 0.8);
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
  align-items: center;
  z-index: 100;
  overflow: hidden;
}

.xerologo {
  height: 7vh;
  width: 120px;
  filter: drop-shadow(0 0 10px rgba(0, 255, 255, 0.7));
}

.logowrapper {
  position: relative;
  display: inline-block;
  perspective: 1000px;
}

.logo3d {
  transition: transform 0.6s;
  transform-style: preserve-3d;
  animation: float 3s ease-in-out infinite;
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0) rotateY(0);
  }
  50% {
    transform: translateY(-10px) rotateY(10deg);
  }
}

.contactbutton {
  padding: 8px;
  background: rgba(15, 17, 22, 0.8);
  color: white;
  border: none;
  /* box-shadow: 0 0 10px rgba(29, 29, 225, 0.7); */
  border-radius: 15px;
  transition: all 0.3s ease;
  cursor: pointer;
  font-size: 1.2rem;
  transition: all 0.3s ease;
}
.contactbutton:hover {
  filter: drop-shadow(0 0 5px rgba(0, 255, 255, 0.7));
}

.contactbutt {
  padding: 8px;
  background: #0f1116;
  color: white;
  border: none;
  /* box-shadow: 0 0 10px rgba(29, 29, 225, 0.7); */

  border-radius: 15px;
  transition: all 0.3s ease;
  cursor: pointer;
  font-size: 1.2rem;
}
.contactbutt:hover {
  filter: drop-shadow(0 0 5px rgba(0, 255, 255, 0.7));
}

.ul-nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.ul-nav-container li {
  list-style: none;
}

.ul-nav-container li a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
  padding: 5px 8px;
  border-radius: 8px;
  position: relative;
  transition: background-color 0.6s ease, color 0.6s ease;
}

.ul-nav-container li a::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0px;
  height: 2px;
  background-color: white;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.ul-nav-container li a:hover::after {
  transform: scaleX(1);
}

.menunav {
  display: none;
}

@media (max-width: 768px) {
  .ul-nav-container {
    display: none;
  }

  .contactbutton {
    display: none;
  }

  .menunav {
    display: block;
    font-size: 2rem;
    cursor: pointer;
    color: white;
    z-index: 300;
  }

  /* Sidebar styling */
  .sidebar {
    position: fixed;
    top: 0;
    right: -100%;
    height: 100%;
    width: 50%;
    background-color: rgba(15, 17, 22, 0.9);
    z-index: 200;
    transition: right 0.3s ease;
    padding: 20px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
  }

  .sidebar.open {
    right: 0;
  }

  .ul-sidebar {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
  }

  .ul-sidebar li {
    margin-bottom: 20px;
  }

  .ul-sidebar li a {
    color: white;
    text-decoration: none;
    font-size: 1.2rem;
    padding: 5px 8px;
    border-radius: 8px;
    position: relative;
    transition: background-color 0.6s ease, color 0.6s ease;
  }

  .ul-sidebar li a:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0px;
    height: 2px;
    background-color: white;
    transform: scaleX(0);
    transition: transform 0.3s ease;
  }

  .ul-sidebar li a:hover::after {
    transform: scaleX(1);
  }
}

/* Hide the sidebar on screens wider than 768px */
@media (min-width: 769px) {
  .sidebar {
    display: none;
  }
}
