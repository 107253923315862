/* Main container */
.texthead_main {
  display: flex;
  flex-direction: column;
  /* width: auto; */
  /* max-width: 1400px; */
  margin: 90px 45px;
  box-sizing: border-box;
  padding: 20px;
  background-color: rgba(178, 178, 178, 1);
  border-radius: 20px;
  font-family: "manrope";
  color: black;
}

/* Text header section */
.text_head {
  text-align: center;
  margin-bottom: 40px;
}

.text_head .p1 {
  font-size: 2.5rem;
  font-weight: 900;
  margin-bottom: 10px;
  font-family: "Space Grotesk";
}

.text_head .p2 {
  font-size: 1.2rem;
  /* max-width: 600px; */
  margin: 0 auto;
  font-weight: 600;
}

/* Video and button section */
.vid_btn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Video and text content */
.Video_text {
  display: flex;
  gap: 20px;
  margin-bottom: 30px;
}

.toggle_video video {
  width: 100%;
  max-width: 456px;
  height: auto;
  border-radius: 8px;
}

.content_toggle {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.toggle_text {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 15px;
  font-family: "Space Grotesk";
}

.toggle_text1 {
  /* font-size: 1rem; */
  font-size: 1.2rem;

  margin-bottom: 10px;
}

/* Button section */
.btn_sec {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.button,
.button1 {
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  font-weight: 600;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button {
  background-color: #007bff;
  color: white;
}

.button1 {
  background-color: #f0f0f0;
  color: #333;
}

@media (max-width: 1208px) {
  .texthead_main {
    margin: 70px 10px;
  }
}

/* Responsive design */
@media (max-width: 768px) {
  .texthead_main {
    margin: 50px 10px;
  }
  .Video_text {
    flex-direction: column;
  }

  .toggle_video video {
    max-width: 100%;
  }

  .text_head .p1 {
    font-size: 2rem;
  }

  .text_head .p2 {
    font-size: 1rem;
  }

  .toggle_text {
    font-size: 1.5rem;
  }

  .toggle_text1 {
    font-size: 0.9rem;
  }

  .button,
  .button1 {
    font-size: 0.9rem;
    padding: 8px 16px;
  }
}

@media (max-width: 480px) {
  .text_head .p1 {
    font-size: 1.8rem;
  }

  .text_head .p2 {
    font-size: 0.9rem;
  }

  .toggle_text {
    font-size: 1.3rem;
  }

  .toggle_text1 {
    font-size: 0.9rem;
  }

  .button,
  .button1 {
    font-size: 0.8rem;
    padding: 6px 12px;
  }
}
