.faq-cont {
  padding: 2rem;
}

.faq-container {
  /* max-width: 1400px; */
  margin: 0 20px;
  font-family: "manrope";
  /* font-family: Arial, Helvetica, sans-serif; */
}

.faq-header {
  text-align: center;
  margin-bottom: 2rem;
}

.faq-question-heading {
  font-size: 1.75rem;
  font-weight: bold;
  /* color: #333; */
  font-family: "Space Grotesk";
}

.faq-columns {
  display: flex;
  gap: 2rem;
  justify-content: center;
}

.faq-column {
  flex: 1;
}

.faq-item {
  height: 110px;
  padding: 1.5rem;
  margin-bottom: 1rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.6s ease;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.faq-item:hover {
  transform: translateY(-5px);

  color: black;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: #dedede; /* Change background color on hover */
}

.faq-question {
  font-size: 1.2rem;
  font-weight: 600;
  /* color: #333; */
  transition: opacity 0.3s ease;
}

.faq-answer {
  font-size: 1.2rem;
  /* color: #555; */
  position: absolute;
  text-align: center;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.6s ease, color 0.6s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 10px;
}

/* When hovered, hide the question and show the answer */
.faq-item:hover .faq-question {
  opacity: 0;
}

.faq-item:hover .faq-answer {
  opacity: 1;
  pointer-events: auto;
  padding: 10px;
}

@media (max-width: 908px) {
  .faq-columns {
    flex-direction: column;
  }
  .faq-item {
    height: 180px;
  }
  .faq-cont {
    padding: 0;
  }

  .faq-column {
    width: 100%;
  }
}
