/* Styles for modal and blurred background */
.modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Blurred overlay */
  backdrop-filter: blur(5px); /* Blur effect */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it appears above everything */
}

.demo_video_modal {
  position: relative;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  max-width: 1000px;
  width: 100%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.demo_video_modal video {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.close_button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 50px;
  cursor: pointer;
  color: #000;
  z-index: 10000;
}

.close_button:hover {
  color: #ff0000;
}

.top_ladning {
  margin-top: 10vh;
  /* background-color: #f0f0f0; */
  padding: 0 5%;
  margin-bottom: 70px;
}

.top_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content {
  /* flex: 1; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.content_top {
  display: flex;
  align-items: baseline;
  /* margin-bottom: 10px; */
}

.words2 {
  font-size: 6vw;
  font-weight: 900;
  margin-right: 15px;
  font-family: Arial, sans-serif;
}

.words {
  display: inline-block;
  overflow: hidden;
  height: 7.3vw;
  font-family: Arial, sans-serif;
}

.words span {
  display: block;
  height: 100%;
  animation: spin_words 6s infinite;
  font-size: 6vw;
  font-weight: 900;
  color: transparent;
  -webkit-text-stroke: 2px #000;
}

.content_bottom {
  margin-top: -1vw;
}

.two,
.word1 {
  font-size: 5vw;
  font-weight: 900;
  font-family: Arial, sans-serif;
  line-height: 1.1;
}

/* New style for CONTENT word */
.word1 {
  color: transparent;
  -webkit-text-stroke: 2px #000;
  font-size: 5vw; /* Matching the size of spinning words */
}

.word1 span {
  color: #000;
  padding: 0;
  margin-left: -20px;
}

.btn_img {
  margin-top: 20px;
  padding: 10px;
  border: 2px solid #000;
  border-radius: 25px;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.5s ease;
}

.btn_img:hover {
  background-color: #000;
  color: #fff;
}

.inner_button {
  display: flex;
  align-items: center;
}

.bt {
  margin-left: 5px;
  /* font-size: 1rem; */
  font-family: "manrope";
}

.Asian_img {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.Asian_img img {
  max-width: 100%;
  height: auto;
}

@keyframes spin_words {
  0%,
  20% {
    transform: translateY(0%);
  }
  25%,
  45% {
    transform: translateY(-100%);
  }
  50%,
  70% {
    transform: translateY(-200%);
  }
  75%,
  95% {
    transform: translateY(-300%);
  }
  100% {
    transform: translateY(-400%);
  }
}

@media (max-width: 768px) {
  .word1 span {
    color: #000;
    padding: 0;
    margin-left: -10px;
  }
  .top_content {
    flex-direction: column;
  }

  .content,
  .Asian_img {
    width: 100%;
  }

  .words2,
  .words span,
  .two,
  .word1 {
    font-size: 8vw;
  }

  .words {
    height: 12vw;
  }

  .words span,
  .word1 {
    -webkit-text-stroke: 1px #000;
  }

  .btn_img {
    /* margin-top: 20px; */
    padding: 5px 10px;
    border-radius: 20px;
  }
}

@media (max-width: 468px) {
  .word1 span {
    color: #000;
    padding: 0;
    margin-left: -5px;
  }
}
