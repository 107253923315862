* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.blog-container {
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  margin-top: 10vh;
  padding: 2rem;
  font-family: "manrope";
}

.Blog_header {
  text-align: center;
  margin-bottom: 3rem;
}

.blog-title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  font-family: "Space Grotesk";
}

.blog-subtitle {
  font-size: 1.2rem;
  color: rgba(67, 67, 67, 0.75);
}

/* Latest blog section */
.Blog_content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.latest-blog {
  position: relative;
  margin-bottom: 2rem;
}

.latest-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  object-fit: cover;
}

.latest-label {
  position: absolute;
  top: 1rem;
  left: 1rem;
  background-color: #fff;
  color: #000;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  font-weight: 600;
}

.latest-title {
  position: absolute;
  bottom: 4rem;
  left: 1rem;
  right: 1rem;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 500;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6);
}

/* Other blogs section */
.other-blogs {
  display: grid;
  gap: 1.5rem;
}

.blog-entry {
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  padding: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.blog-entry:hover {
  transform: translateY(-5px);
}

.blog-date {
  color: rgba(0, 0, 0, 0.5);
  font-size: 0.9rem;
  /* margin-bottom: 0.5rem; */
}

.blog-titl {
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.know-more-btn {
  background: none;
  color: #007bff;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  transition: color 0.3s ease;
}

.know-more-btn:hover {
  color: #0056b3;
}

/* Latest blogs grid */
.blogs-grid {
  display: grid;
  gap: 2rem;
  margin-top: 3rem;
}

.blog-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
  object-position: center;
}

.blog-content {
  padding: 1.5rem;
}

.blog-entry-title {
  font-size: 1.3rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.blog-description {
  font-size: 1rem;
  margin-bottom: 1rem;
}

/* Developers section */
.developers-grid {
  display: grid;
  gap: 2rem;
  margin-top: 3rem;
}

.developer-entry {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.developer-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.developer-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1.5rem;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
  color: white;
}

.developer-title {
  font-size: 1.3rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.developer-description {
  font-size: 1rem;
  margin-bottom: 1rem;
}

/* Media Queries */
@media (min-width: 768px) {
  .Blog_content {
    flex-direction: row;
  }

  .latest-blog {
    flex: 2;
  }

  .other-blogs {
    flex: 1;
  }

  .blogs-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .developers-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .blogs-grid {
    grid-template-columns: repeat(3, 1fr);
  }

  .developers-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}
