.trusted-by-brands {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
  background-color: #dedede;
  margin: 70px auto;
  box-sizing: border-box;
  font-family: "manrope";
}

.trusted-by-brands-content {
  position: relative;
  z-index: 10;
  color: black;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.trusted_main {
  font-size: 50px;
  font-family: "Space Grotesk";
  text-align: center;
}

.trusted_text {
  text-align: center;
  padding: 0 10px;
  font-size: 1.5rem;
}

.logo-slider {
  width: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.1);
}

.logo-slider-wrapper {
  display: flex;
  animation: slide 10s linear infinite;
}

.logo-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  flex-shrink: 0;
}

.logo-item img {
  max-width: 100%;
  height: clamp(30px, 10vw, 60px);
  object-fit: contain;
  margin-bottom: 10px;
}

.company_name {
  color: #ffffff;
  font-size: clamp(0.7rem, 1.5vw, 0.8rem);
  margin-top: 5px;
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

@media (max-width: 1200px) {
}

@media screen and (max-width: 768px) {
  .trusted_main {
    font-size: 40px;
  }

  .trusted_text {
    font-size: 1.2rem;
  }

  .logo-item {
    padding: 0 15px;
  }
}

@media screen and (max-width: 480px) {
  .trusted_main {
    font-size: 30px;
  }

  .trusted_text {
    font-size: 1rem;
  }

  .logo-item {
    padding: 0 10px;
  }
}
