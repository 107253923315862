.footer {
  background-color: #3c3e42;
  color: white;
  font-family: "manrope";
  padding: 2rem 1rem;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
}

.footer-logo {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  text-align: center;
  font-family: "Space Grotesk";
  letter-spacing: 2px;
}

.footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer-section {
  flex: 1 1 200px;
  margin-bottom: 1.5rem;
}

.footer-section h3 {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  /* text-align: center; */
}

.footer-section ul {
  list-style-type: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 0.5rem;
}

.footer-section a {
  color: white;
  text-decoration: none;
}

.footer-section a:hover {
  text-decoration: underline;
}
.iconheader {
  text-align: center;
}

.social-icons {
  display: flex;
  justify-content: center;
  /* gap: 1rem; */
}

.social-icons a {
  font-size: 1.5rem;
}

/* Responsive styles */
@media (max-width: 780px) {
  .footer-content {
    flex-wrap: wrap;
  }

  .footer-section {
    flex: 1 1 40%;
  }
}

@media (max-width: 450px) {
  .footer-section {
    flex: 1 1 100%;
  }
}
