/* Real container and background video styles */
.real-container {
  position: relative;
  height: 120vh;
  overflow: hidden;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.realestate-text {
  position: relative;
  color: white;
  top: 75vh;
  left: 50px;
  z-index: 2;
  font-size: 3rem;
  margin-top: -30px;
  font-family: "Space Grotesk";
}

/* Button styles */
.relestate-buttons {
  display: flex;
  justify-content: center;
  padding: 30px 0;
}

.real1-button,
.real2-button {
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.real1-button.active,
.real1-button {
  background-color: #007bff;
  color: white;
}

.real2-button {
  background-color: white;
  color: #007bff;
  border: 1px solid #007bff;
}

.real2-button.active {
  background-color: #ff6347;
  color: white;
  border: none;
}

.real1-button:hover,
.real2-button:hover {
  opacity: 0.9;
}

/* Flex container and product styles */
.flex-container {
  /* max-width: 1500px; */
  margin: 0 auto;
  padding: 20px;
  font-family: "manrope";
}

@media (max-width: 789px) {
  .flex-container {
    padding: 0px;
  }
}
.product-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 50px;
  align-items: center;
}

.product-container.reverse {
  flex-direction: row-reverse;
}

.product-content {
  flex: 1;
  padding: 20px;
}

.product-content .titlee_text {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 15px;
  color: black;
}

.product-content .desc {
  font-size: 1rem;
  line-height: 1.6;
  color: #333;
}

.product-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-image .pro_img {
  width: 100%;
  max-width: 600px;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Media Queries */
@media (min-width: 1024px) {
  .product-container {
    gap: 40px;
  }

  .product-image .pro_img {
    max-width: 700px;
  }
}

@media (max-width: 768px) {
  .background-video {
    object-fit: cover;
  }

  .realestateheader {
    position: relative;
    top: 70vh;
    left: 10px;
    z-index: 1000;
    color: white;
    font-size: 2rem;
  }

  .realestate-text {
    position: relative;
    color: white;
    top: 55vh;
    left: 10px;
    z-index: 1;
    font-size: 1.3rem;
    margin-top: -30px;
  }

  .real-container {
    height: 65vh;
    background-color: white !important;
  }

  .product-container,
  .product-container.reverse {
    flex-direction: column;
  }

  .product-image .pro_img {
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .product-container {
    padding: 10px;
    margin: 40px 10px;
  }

  .product-content .titlee_text {
    font-size: 1.5rem;
  }

  .product-content .desc {
    font-size: 0.9rem;
  }

  .background-video {
    object-fit: cover;
    height: 400px;
    margin: 0;
  }

  .realestate-text {
    top: 50vh;
    font-size: 1rem;
  }

  .real-container {
    height: 55vh;
  }
}
