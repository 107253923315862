* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main {
  background-color: #efefef;
  /* padding: 10px; */
}
