@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.different-from-service3 {
  margin: 1rem; /* Updated margin to match original code */
  padding: 2rem;
  background-color: #f0f0f0; /* Added background color from the second code */
  text-align: center;
  font-family: Arial, sans-serif;
}

.Differents3 {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  color: #333;
  margin-bottom: 2rem;
  position: relative;
  z-index: 2;
  font-family: Arial, sans-serif; /* Added font-family */
}

.feature-cards3 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  font-family: Arial, sans-serif;
}

.feature-card3 {
  position: relative;
  flex: 1 1 250px;
  max-width: 300px;
  min-height: 120px; /* Added min-height from original code */
  background-color: #d4dae5;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
}

.feature-card3::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 10px;
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  background-size: 400% 400%;
  animation: glowing 7.9s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 0;
}

.feature-card3:hover::before {
  opacity: 1;
}

.feature-card3::after {
  z-index: 1;
  content: "";
  position: absolute;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  left: 2px;
  top: 2px;
  background-color: #d4dae5;
  border-radius: 6px;
}

.feature-card3:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.title_differents3 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 0.5rem;
  position: relative;
  z-index: 2;
}

.description_differents3 {
  font-size: 1rem;
  color: #4a4a4a;
  position: relative;
  z-index: 2;
}

/* @media (max-width: 768px) {
    .feature-cards3 {
        flex-direction: column;
    }

    .feature-card3 {
        flex: 1 1 100%;
        max-width: none;
    }
} */

@media (max-width: 480px) {
  .Differents3 {
    font-size: 1.5rem;
  }

  .feature-card3 {
    flex-direction: column;

    padding: 1rem;
  }

  .title_differents3 {
    font-size: 1.1rem;
  }

  .description_differents3 {
    font-size: 0.9rem;
  }
}
