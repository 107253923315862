.about-main {
  background-color: #efefef;
  color: #1e1e1e;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  box-sizing: border-box;
  font-family: "manrope";
}

.landing-section {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 4rem;
  margin-top: 2rem;
}

.landing-content {
  flex: 1;
}

.about-title {
  font-size: 4rem;
  font-weight: 800;
  margin-bottom: 1rem;
  font-family: "Space Grotesk";
}

.about-title span {
  color: #ffa500;
}

.about-description {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #525252;
}

.landing-image {
  max-width: 100%;
  height: auto;
}

.stats-section {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 4rem;
}

.stat-item {
  background: white;
  border-radius: 20px;
  padding: 1.5rem;
  text-align: center;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  flex: 1;
  min-width: 150px;
}

.stat-item:hover {
  transform: scale(1.05);
}

.stat-icon {
  font-size: 2.5rem;
  color: #171715;
  margin-bottom: 0.5rem;
}

.stat-number {
  font-size: 1.5rem;
  font-weight: bold;
  color: #fbb030;
  margin: 0;
}

.stat-label {
  font-size: 1rem;
  color: #666;
  margin: 0;
}

.vision-mission-section {
  display: flex;
  gap: 2rem;
  margin-bottom: 4rem;
}

.vision-mission-item {
  flex: 1;
  background-color: rgb(223, 232, 232);
  border-radius: 20px;
  padding: 2rem;
  position: relative;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
}

.vision-mission-icon {
  width: 80px;
  height: 80px;
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.vision-mission-content {
  margin-top: 4rem;
}

.vision-mission-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.vision-mission-subtitle {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 1rem;
}

.vision-mission-text {
  font-size: 1rem;
  line-height: 1.6;
}

.team-section {
  text-align: center;
}

.section-title {
  font-size: 2.5rem;
  font-weight: 800;
  margin-bottom: 1rem;
}

.section-description {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 2rem;
}

.team-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
}

.team-member {
  text-align: center;
}

.team-member img {
  width: 100%;
  max-width: 200px;
  border-radius: 50%;
  margin-bottom: 1rem;
}

.member-name {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.member-role {
  font-size: 1rem;
  color: #666;
}

@media screen and (max-width: 768px) {
  .landing-section {
    flex-direction: column;
    margin-top: 3rem;
  }

  .about-title {
    font-size: 3rem;
  }

  .vision-mission-section {
    flex-direction: column;
  }

  .team-grid {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
}

@media screen and (max-width: 480px) {
  .about-main {
    padding: 1rem;
  }
  .landing-section {
    margin-top: 4rem;
  }

  .about-title {
    font-size: 2.5rem;
  }

  .stats-section {
    flex-direction: column;
  }

  .stat-item {
    width: 100%;
  }

  .team-grid {
    grid-template-columns: 1fr;
  }
}
