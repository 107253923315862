.mid_main {
  width: 100%;
  /* max-width: 1500px; */
  margin: 0 auto;
  padding: 20px;
  font-family: "manrope";
}

.headercat {
  text-align: center;
  font-size: 1.5rem;
  font-family: "Space Grotesk";
  letter-spacing: 7px;
  font-weight: bold;
}

.Animated_text {
  position: relative;
}

.card {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  height: 350px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  transition: all 0.3s ease;
  padding-bottom: 5px;
}

.img_mid {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.text_container {
  padding: 20px;
}

.heading_text {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.text_content {
  font-size: 1rem;
  line-height: 1.4;
}

/* Video styles */
.card video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

/* Hover effect */
.card:hover .overlay {
  background: rgba(0, 0, 0, 0.5);
}

.card:hover video {
  opacity: 1;
}

/* Loading overlay */
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Slider specific styles */
.slick-slide {
  padding: 0 10px;
}

.slick-prev,
.slick-next {
  z-index: 1;
}

/* Move dots down */
.slick-dots {
  bottom: -30px; /* Adjust this value to move dots further down */
}

/* Change color of prev/next buttons to black */
.slick-prev:before,
.slick-next:before {
  color: black;
}

.slick-prev {
  left: 0px;
  color: black;
  /* font-size: 10rem; */
}

.slick-next {
  right: 0px;
}

/* Responsive styles */
@media (max-width: 1200px) {
  .card {
    height: 350px;
  }

  .heading_text {
    font-size: 1.2rem;
  }

  .text_content {
    font-size: 0.9rem;
  }
}

@media (max-width: 992px) {
  .card {
    height: 350px;
  }
}

@media (max-width: 750px) {
  .card {
    height: 350px;
    width: 100% !important;
  }
  .headercat {
    text-align: center;
    font-size: 1rem;
    /* margin-bottom: 15px; */
  }

  .heading_text {
    font-size: 1.2rem;
  }

  .text_content {
    font-size: 0.9rem;
  }
}

@media (max-width: 500px) {
  .card {
    height: 350px;
    width: 100% !important;
  }

  .heading_text {
    font-size: 1rem;
  }

  .text_content {
    font-size: 0.9rem;
  }
}
