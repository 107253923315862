.extented_main {
  width: 100%;
  margin: 10px auto;
  font-family: "manrope";
}

.headlineservice {
  text-align: center;
  font-size: 1.5rem;
  font-family: "Space Grotesk";
  letter-spacing: 7px;
  font-weight: bold;
}

.Extend_reality {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.service-item {
  width: 350px;
  height: 350px;
}

.service-inner {
  width: 100%;
  height: 100%;
  background-color: #dedede;
  border-radius: 10px;
  padding: 25px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.service-content {
  width: 100%;
  height: 100%;
  position: relative;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.service-item:hover .service-content {
  transform: rotateY(180deg);
}

.service-front,
.service-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Ensure vertical centering */
  /* color: white; */
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
}

.service-back {
  transform: rotateY(180deg);
}

.md_img {
  max-width: 85%;
  height: auto;
  margin-bottom: 20px;
  object-fit: contain;
}

.Heading_one,
.Heading_two {
  margin: 0 0 10px 0;
  font-size: 1.5em;
}

.service-text {
  font-size: 16px;
  line-height: 1.6;
  max-width: 90%;
  margin: 0 auto;
  text-align: left;
}

@media (max-width: 1100px) {
  .Extend_reality {
    justify-content: space-around;
  }
}

@media (max-width: 768px) {
  .headlineservice {
    text-align: center;
    font-size: 1rem;
  }
  .Extend_reality {
    flex-direction: column;
    align-items: center;
  }
  .service-item {
    width: 100%;
    max-width: 350px;
  }
}
