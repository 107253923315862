/* Container for the entire head section */

.services-container {
  margin-top: 10vh;
}
.head2 {
  width: 100%;
  /* max-width: 1400px; */
  margin: 0 auto;
  padding: 20px;
  font-family: "Space Grotesk";
}

/* Container for the slider */
.slider-container {
  position: relative;
  overflow: hidden;
}

/* Individual slide container */
.slide-container {
  position: relative;
}

/* Header image styling */
.header-image {
  width: 100%;
  height: 600px;
  object-fit: cover;
  border-radius: 20px;
}

/* Statement overlay styling */
.stmnt {
  position: absolute;
  bottom: 100px;
  left: 20px;
  right: 20px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 15px;
  font-size: 2rem;
  text-align: center;
  border-radius: 5px;
  letter-spacing: 2px;
}

/* Slick slider custom styles */
.slick-dots {
  bottom: 10px;
}

.slick-dots li button:before {
  color: white;
  opacity: 0.5;
}

.slick-dots li.slick-active button:before {
  color: white;
  opacity: 1;
}

.slick-prev,
.slick-next {
  z-index: 1;
}

.slick-prev {
  left: 10px;
}

.slick-next {
  right: 10px;
}

.slick-prev:before,
.slick-next:before {
  font-size: 24px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .header-image {
    height: 300px;
  }

  .stmnt {
    font-size: 1rem;
    bottom: 10px;
    left: 10px;
    right: 10px;
  }
}

/* service section  */
.services {
  margin: 50px 0px;
  font-family: "manrope";
}

.Our_Services {
  font-size: 3rem;
  justify-content: center;
  display: flex;
  /* margin-top: 2vw; */

  font-style: normal;
  font-weight: 800;
  line-height: normal;
  font-family: "Space Grotesk";
}

.Service_st {
  color: #000;
  text-align: center;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.service-grid {
  width: 80%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin: 30px auto;
  padding: 20px;
}

.service-itemss {
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.service-itemss:hover {
  transform: scale(1.05);
}

.service-itemss img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 15px;
  transition: filter 0.3s ease;
}

.service-itemss:hover img {
  filter: brightness(70%);
}

.service-itemss p {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: white;
  font-size: 18px;
  font-weight: bold;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.service-itemss:hover p {
  opacity: 1;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .service-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .services {
    margin-top: 10vh;
  }
}

@media (max-width: 768px) {
  .service-grid {
    grid-template-columns: 1fr;
    padding: 0;
  }
  .services {
    margin: 40px 0px;
  }

  .Our_Services {
    font-size: 2rem;
  }

  .Service_st {
    font-size: 20px;
    padding: 0 10px;
  }
}

@media (max-width: 480px) {
  .Our_Services {
    font-size: 2rem;
  }
  .services {
    margin: 20px 0px;
  }
  .Service_st {
    font-size: 20px;
    padding: 0 10px;
  }
}

/* // project section  */

.projects-container {
  /* max-width: 1200px; */
  margin: 0 60px;
  padding: 2rem;
  font-family: "manrope";
}

.projects-header {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2.5rem;
  /* color: #333; */
  font-family: ;
}

.project-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.service-itemaa {
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 1.5rem;
  text-align: center;
  transition: transform 0.3s ease;
  position: relative;
  overflow: hidden;
}

.service-itemaa::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0;
  background-color: rgba(255, 255, 255, 0.2);
  transition: height 0.3s ease;
}

.service-itemaa:hover {
  transform: translateY(-5px);
}

.service-itemaa:hover::before {
  height: 100%;
}

.service-icon {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #007bff;
}

.service-itemaa h3 {
  font-size: 1.2rem;
  margin: 0;
  color: #333;
}

@media (max-width: 768px) {
  .projects-container {
    padding: 1rem;
    margin: 0 20px;
  }

  .projects-header {
    font-size: 1.5rem;
  }

  .project-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
  }

  .service-itemaa {
    padding: 1rem;
  }

  .service-icon {
    font-size: 2rem;
  }

  .service-itemaa h3 {
    font-size: 1rem;
  }
}
