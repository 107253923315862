@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.different-from-others {
  text-align: center;
}

/* Add this to Services2.css */
.responsive-image {
  width: 100%;
  height: auto;
  max-width: 1200px;
}

.Different {
  text-align: center;
  font-size: 3rem;
  font-family: "Space Grotesk";
  /* letter-spacing: 7px; */
  font-weight: bold;
}
@media (max-width: 789px) {
  .Different {
    font-size: 2rem;
  }
}

.feature-cards {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
}

.feature-card {
  position: relative;
  flex: 1 1 250px;
  max-width: 300px;
  min-height: 120px;
  background-color: #d4dae5;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
  font-family: Arial, sans-serif;
}

.feature-card::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 10px;
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  background-size: 400% 400%;
  animation: glowing 7.9s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 0;
}

.feature-card:hover::before {
  opacity: 1;
}

.feature-card::after {
  z-index: 1;
  content: "";
  position: absolute;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  left: 2px;
  top: 2px;
  background-color: #d4dae5; /* Changed from white to #D4DAE5 */
  border-radius: 6px;
}

.feature-card:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.title_different {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 0.5rem;
  position: relative;
  z-index: 2;
}

.description_different {
  font-size: 1rem;
  color: #4a4a4a;
  position: relative;
  z-index: 2;
}

@media (max-width: 480px) {
  .Different {
    font-size: 1rem;
  }

  .feature-card {
    flex-direction: column;
    /* padding: 1rem; */
  }

  .title_different {
    font-size: 1.1rem;
  }

  .description_different {
    font-size: 0.9rem;
  }
}
